<template>
  <div class="header-container" :style="color ? 'color: ' + colorText + '!important; background-color: ' + color + '!important;' : ''">
    {{ title }}

  </div>
</template>
<script>
export default {
  props: {
    colorText: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    search: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
  }
}
</script>
<style scoped lang="scss">
.header-container {
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 40px;
  min-height: 40px;
}
</style>
